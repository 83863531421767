


















import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UofTQuiz2Question2',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        explanation: null as string | null,
      },
    };
  },
  computed: {
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 1;
    },
    questionText(): string {
      if (this.version === 1) {
        return 'Which among the two molecules shown below will have a higher boiling point? Be sure to explain your answer.';
      } else if (this.version === 2) {
        return 'Which among the two molecules shown below will have a higher boiling point?  Be sure to explain your answer.';
      } else if (this.version === 3) {
        return 'Explain why the water solubility trend depicted in the image below is observed.';
      } else {
        return 'Error';
      }
    },
    imgWidth(): number {
      if (this.version === 1) {
        return 340;
      } else if (this.version === 2) {
        return 380;
      } else {
        return 270;
      }
    },
    image(): string {
      return `/img/assignments/UofT/CHMB41H/2023/Quiz2/Question2/v${this.version}.png`;
    },
  },
});
